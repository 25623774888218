import {
  Button,
  LoadingSpinner,
  Typography,
} from '@eucalyptusvc/design-system';

import { OTCPurchaseCompletedProps } from './types';
import { FormattedMessage } from 'react-intl';
import { gql, useQuery } from '@apollo/client';
import { BillingDetails } from '../../billing-details';
import {
  OtcPurchaseCompletedQuery,
  OtcPurchaseCompletedQueryVariables,
} from '@customer-frontend/graphql-types';

export const OTCPurchaseCompleted = ({
  onBackToProfile,
  image,
}: OTCPurchaseCompletedProps): JSX.Element => {
  const { loading, data } = useQuery<
    OtcPurchaseCompletedQuery,
    OtcPurchaseCompletedQueryVariables
  >(
    gql`
      query OTCPurchaseCompleted {
        profile {
          id
          firstName
          lastName
          fullName
          email
          phone
          ...BillingDetails
          address {
            id
            line1
            line2
            city
            state
            postalCode
            country
          }
        }
      }
      ${BillingDetails.fragment}
    `,
    {
      fetchPolicy: 'cache-first',
    },
  );

  if (loading) {
    return (
      <div className="flex justify-center py-5">
        <LoadingSpinner />
      </div>
    );
  }

  const firstName = data?.profile?.firstName;
  const email = data?.profile?.email;

  return (
    <div className="text-center px-6 py-8 space-y-5">
      <div className="space-y-4">
        <Typography element="h3" size="md">
          <FormattedMessage
            defaultMessage="Thank you for your purchase{hasFirstName, select, true {, {firstName}} other {}}!"
            description=""
            values={{ hasFirstName: !!firstName, firstName }}
          />
        </Typography>
        <Typography size="medium-paragraph">
          <FormattedMessage
            defaultMessage="An order confirmation has been sent to <a>{email}</a>."
            description=""
            values={{
              a: (chunks) => (
                <Typography element="span" size="medium-paragraph" isBold>
                  {chunks}
                </Typography>
              ),
              email,
            }}
          />
        </Typography>
        <Typography size="medium-paragraph">
          <FormattedMessage defaultMessage="Please allow up to 2 business days for us to process and ship your order." />
        </Typography>
      </div>
      <div className="flex justify-center"> {image}</div>
      <Button palette="alternate" isFullWidth onClick={onBackToProfile}>
        <FormattedMessage
          defaultMessage="Close"
          description="Button text to translate the purchase completed component"
        />
      </Button>
    </div>
  );
};
