import { useQuery, gql, QueryResult, QueryHookOptions } from '@apollo/client';
import { Treatment } from '@customer-frontend/graphql-types';

type TreatmentsResponse = { treatments: Treatment[] };

export const useTreatments = (
  options?: QueryHookOptions,
): QueryResult<TreatmentsResponse> => {
  return useQuery<TreatmentsResponse>(
    gql`
      query UseTreatments {
        treatments {
          id
          type
          active
          status
          refillsLeft
          hasUnfulfilledOrder
          nextScheduledDate
          nextPayment
          consultation {
            id
            status
            isApproved
            stage
            quizApplication {
              id
            }
            doctor {
              id
              firstName
              lastName
              shortClinicianName
              fullClinicianName
              fullName
              provider {
                id
                clinicianType
                clinicianTitle
              }
            }
          }

          otcSchedules {
            id
            createdAt
            updatedAt
            cadence
            isActive
            quantity
            product {
              id
              name
              variants {
                id
                price
              }
            }
          }
          followupConsultations(orderBy: { createdAt: asc }) {
            id
            status
            stage
            isApproved

            doctor {
              id
              firstName
              lastName
              shortClinicianName
              fullClinicianName
              fullName
            }
          }
          product {
            id
            name
          }
          script {
            id
            createdAt
          }
        }
      }
    `,
    options,
  );
};
