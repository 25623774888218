import { useConsultation, useProfile } from '@customer-frontend/services';
import { isIOS } from '@customer-frontend/utils';
import {
  Button,
  LoadingSpinner,
  useNotification,
} from '@eucalyptusvc/design-system';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { routes } from 'utils/routes';

import SimplePageLayout from 'components/layout/page/simple-page-layout';
import { FertilityTestConfirmCard } from './fertility-test-confirm-card';
import { FertilityTestInfoCard } from './fertility-test-info-card';
import { downloadPdfFile } from 'utils/download-file';

const FertilityTestConfirm = (): JSX.Element => {
  const notify = useNotification();
  const history = useHistory();
  const { consultationId } = useParams<{ consultationId: string }>();
  const { loading: loadingProfile, data: profileData } = useProfile();
  const { loading: loadingConsultation, data: consultationData } =
    useConsultation(consultationId);

  if (
    loadingProfile ||
    !profileData ||
    loadingConsultation ||
    !consultationData
  ) {
    return (
      <div className="flex justify-center p-5">
        <LoadingSpinner />
      </div>
    );
  }
  const user = profileData?.profile;
  const consultation = consultationData.consultation;

  const isFertilityTest = consultation.type === 'FERTILITY_HORMONE';
  if (!isFertilityTest) {
    return <Redirect to={routes.profile} />;
  }
  if (consultation.status === 'AWAITING_PAYMENT') {
    return (
      <Redirect
        to={routes.consultation.fertilityTest.payment(consultation.id)}
      />
    );
  }

  const handleDownloadReferral = (): Promise<void> | void | undefined => {
    const signedReferralUrl =
      consultation.pathologyRequests?.[0]?.referral?.signedUrl;

    const handleError = (): void => {
      notify.error({
        message:
          'Could not download PDF, please contact us at hello@kinfertility.com.au',
      });
    };

    if (!signedReferralUrl) {
      return handleError();
    }

    // Open new tab for ios, as blob download is not supported
    if (isIOS()) {
      window.open(signedReferralUrl);
      return;
    }

    const fileTitle = user.lastName?.toLocaleLowerCase() ?? 'referral';
    return downloadPdfFile({
      url: signedReferralUrl,
      fileName: `${fileTitle}-request`,
      onError: handleError,
    });
  };

  return (
    <SimplePageLayout className="space-y-8">
      <FertilityTestConfirmCard
        handleDownloadReferral={handleDownloadReferral}
        postcode={user?.address?.postalCode ?? ''}
      />
      <FertilityTestInfoCard />
      <Button
        onClick={() => history.push(routes.profile)}
        level="secondary"
        isFullWidth
      >
        Back to profile
      </Button>
    </SimplePageLayout>
  );
};

export default FertilityTestConfirm;
