import { Typography } from '@eucalyptusvc/design-system';
import { BillingDetails } from '@customer-frontend/order';
import type { OTCPaymentDetailsProps } from './types';
import { FormattedMessage } from 'react-intl';

export const OTCPaymentDetails = ({
  savedPaymentMethods,
  hasZip,
}: OTCPaymentDetailsProps): JSX.Element => {
  return (
    <div className="space-y-4">
      <Typography element="h3" size="sm" isBold>
        <FormattedMessage
          defaultMessage="Payment details"
          description="Title for the Payment details section of the OTC cart"
        />
      </Typography>
      <BillingDetails
        hasZip={hasZip}
        savedPaymentMethods={savedPaymentMethods}
        hidePaymentMethodLabel={!hasZip}
      />
    </div>
  );
};
