import {
  useQuery,
  gql,
  QueryResult,
  DocumentNode,
  WatchQueryFetchPolicy,
} from '@apollo/client';
import {
  Treatment,
  QueryTreatmentArgs,
} from '@customer-frontend/graphql-types';
import { treatmentFragment } from '../graphql/fragments';

export function useTreatment(
  id: string,
  fragment: DocumentNode = treatmentFragment,
  fetchPolicy?: WatchQueryFetchPolicy,
): QueryResult<{ treatment: Treatment }, QueryTreatmentArgs> {
  return useQuery<{ treatment: Treatment }, QueryTreatmentArgs>(
    gql`
      query UseTreatment($id: String!) {
        treatment(id: $id) {
          ...TreatmentInfo
        }
      }
      ${fragment}
    `,
    {
      variables: {
        id,
      },
      fetchPolicy,
    },
  );
}
