import React, { useEffect, useState } from 'react';
import { useAuth } from '@customer-frontend/auth';

import type { HeaderProps } from './types';
import { HeaderView } from './view';

const mainMenuItems = [
  'Contraception',
  'Fertility',
  'Conceiving',
  'Pregnancy',
  'Postpartum',
  'Supplements',
];

export const Header = ({
  color = 'cream',
}: HeaderProps): React.ReactElement => {
  const [hoveredMenuItem, setHoveredMenuItem] = useState<string>();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const { loggedInUser } = useAuth();

  const handleMobileMenuToggle = (): void => {
    setIsMobileMenuOpen((isOpen) => !isOpen);
  };

  useEffect(() => {
    const handleResize = (): void => {
      if (window.innerWidth >= 1024) {
        setIsMobileMenuOpen(false);
      } else {
        setHoveredMenuItem(undefined);
      }
    };

    window.addEventListener('resize', handleResize, { passive: true });

    return (): void => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <HeaderView
      user={loggedInUser}
      hoveredMenuItem={hoveredMenuItem}
      mobileMenuOpen={isMobileMenuOpen}
      mainMenuItems={mainMenuItems}
      color={color}
      onMenuItemHover={setHoveredMenuItem}
      onMobileMenuToggle={handleMobileMenuToggle}
    />
  );
};
