import { FC, SVGProps } from 'react';
import { Typography, Button } from '@eucalyptusvc/design-system';
import { formatDoctorName, PrescriberType } from '@customer-frontend/doctor';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Maybe } from '@customer-frontend/graphql-types';
import { formatDate } from '@eucalyptusvc/lib-localization';
import { mapBrandToAdaptersBrand } from '@customer-frontend/types';
import { getConfig } from '@customer-frontend/config';

const Scheduled = ({
  reminder,
  doctorName,
  Icon,
}: {
  Icon: FC<SVGProps<SVGSVGElement>>;
  reminder: { scheduledAt: string };
  doctorName: string;
}): React.ReactElement => {
  const config = getConfig();
  return (
    <>
      <Typography size="medium-paragraph" isBold>
        <Icon className="inline-block mr-2 w-4 h-4" />
        <FormattedMessage
          defaultMessage="{doctorName} has set a reminder for {scheduledAt}"
          values={{
            doctorName,
            scheduledAt: formatDate(
              mapBrandToAdaptersBrand(config.brand),
              reminder.scheduledAt,
              { dateStyle: 'long' },
            ),
          }}
        />
      </Typography>
      <Typography size="paragraph">
        <FormattedMessage defaultMessage="Feel free to continue your consult when you're ready." />
      </Typography>
    </>
  );
};

const Triggered = ({
  doctorName,
  Icon,
}: {
  Icon: FC<SVGProps<SVGSVGElement>>;
  doctorName: string;
}): React.ReactElement => (
  <>
    <Typography size="medium-paragraph" isBold>
      <Icon className="inline-block mr-2 w-4 h-4" />
      <FormattedMessage
        defaultMessage="{doctorName} would like to hear from you"
        values={{ doctorName }}
      />
    </Typography>
    <Typography size="paragraph">
      <FormattedMessage defaultMessage="Please let them know whether you would like to proceed." />
    </Typography>
  </>
);

export const ReminderCardContent = ({
  consultation,
  reminder,
  scheduledIcon,
  triggeredIcon,
  consultRoute,
}: {
  scheduledIcon: FC<SVGProps<SVGSVGElement>>;
  triggeredIcon: FC<SVGProps<SVGSVGElement>>;
  consultRoute: string;
  consultation: {
    doctor?: {
      fullName?: Maybe<string>;
      fullClinicianName: string;
      shortClinicianName: string;
    } | null;
    allowPatientToSendMessageToDoctor: boolean;
  };
  reminder: { scheduledAt: string; doneAt?: string | null };
}): React.ReactElement => {
  const history = useHistory();
  const { formatMessage } = useIntl();
  const isTriggered = reminder.doneAt !== null;
  const doctorName = consultation.doctor
    ? formatDoctorName(consultation.doctor, PrescriberType.DOCTOR)
    : formatMessage({ defaultMessage: 'Your practitioner' });

  return (
    <div className="space-y-4">
      {isTriggered ? (
        <Triggered Icon={triggeredIcon} doctorName={doctorName} />
      ) : (
        <Scheduled
          Icon={scheduledIcon}
          reminder={reminder}
          doctorName={doctorName}
        />
      )}
      {consultation.allowPatientToSendMessageToDoctor && (
        <Button
          eventElementName="consultationReminderCardButton"
          onClick={() => {
            history.push(consultRoute);
          }}
          isFullWidth
        >
          {isTriggered
            ? formatMessage({ defaultMessage: 'Send a message' })
            : formatMessage({ defaultMessage: 'View consult' })}
        </Button>
      )}
    </div>
  );
};
