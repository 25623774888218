import { Typography } from '@eucalyptusvc/design-system';
import { formatCentsToCurrency, useOTCCart } from '@customer-frontend/order';
import { QuantityInput } from '../../../quantity-input';

export const OTCOrderDetails = (): JSX.Element => {
  const { updateItemInCart, cartItems, updateLoading } = useOTCCart();
  const {
    variant: { id, price, name, product },
    quantity,
  } = cartItems[0];
  return (
    <div className="flex gap-4">
      <img
        className="object-cover w-32 h-32 rounded-lg"
        src={product.photo?.url}
      />
      <div className="space-y-3">
        <div className="space-y-2">
          <Typography element="h3" size="sm" isBold>
            {name}
          </Typography>
          <Typography size="medium-paragraph" isBold>
            {formatCentsToCurrency(price)}
          </Typography>
        </div>
        <QuantityInput
          disabled={updateLoading}
          value={quantity}
          onChange={(value: number) => updateItemInCart(id, value)}
        />
      </div>
    </div>
  );
};
