import React from 'react';

import type { HeaderColor } from '../header/types';
import { Header } from '../header';
import { Footer } from '../footer';

interface LayoutProps {
  headerColor?: HeaderColor;
  children: React.ReactNode;
}

export const Layout = ({
  headerColor,
  children,
}: LayoutProps): React.ReactElement => {
  return (
    <div className="flex flex-col min-h-screen bg-beige-500 text-primary-800 pt-16">
      <Header color={headerColor} />
      <main className="flex-grow">{children}</main>
      <Footer />
    </div>
  );
};

// Temporary solution until all layout color variants are ready
export const PurpleHeaderLayout = ({
  children,
}: Omit<LayoutProps, 'headerColor'>): JSX.Element => (
  <Layout headerColor="purple">{children}</Layout>
);
