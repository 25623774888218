import { environment } from 'utils/environment';

export const EmbeddedMap = ({
  searchQueryString,
}: {
  searchQueryString?: string;
}): JSX.Element => {
  const { mapsApiKey } = environment;
  return (
    <iframe
      width="100%"
      height="300"
      loading="lazy"
      frameBorder="0"
      style={{ border: 'none' }}
      src={`https://www.google.com/maps/embed/v1/search?key=${mapsApiKey}&q=${searchQueryString}`}
    />
  );
};
