import { SimpleHeader } from 'components/navigation/simple-header';
import { ReactNode } from 'react';
import { useHistory } from 'react-router-dom';

export const ChatLayout: React.FC<{ children: ReactNode }> = ({ children }) => {
  const history = useHistory();

  return (
    <main className="absolute h-full min-h-full w-screen sm:w-full mx-auto pt-20">
      <SimpleHeader onBackClick={{ callback: () => history.goBack() }} />
      {children}
    </main>
  );
};
