import React from 'react';
import { Progress, SetProgressContext } from '@customer-frontend/navbar';
import {
  SetBackButtonBehaviourContext,
  BackButtonBehaviour,
} from '@customer-frontend/services';

import { SimpleHeader } from '../simple-header';
import { ProgressBar } from '../../progress-bar';
import { shouldShowProgressBar, calcProgressValue } from './utils';

interface SimpleLayoutProps {
  children: React.ReactNode;
}

export const SimpleLayout = ({
  children,
}: SimpleLayoutProps): React.ReactElement => {
  const [progress, setProgress] = React.useState<Progress | null>(null);
  const [backButtonBehaviour, setBackButtonBehaviour] =
    React.useState<BackButtonBehaviour | null>(null);

  return (
    <div className="flex flex-col min-h-screen bg-beige-500 text-primary-800 pt-20">
      <SimpleHeader onBackClick={backButtonBehaviour} />
      {progress && shouldShowProgressBar(progress) && (
        <ProgressBar
          className="mt-4 mx-4 lg:mx-8"
          value={calcProgressValue(progress)}
          size="sm"
        />
      )}
      <main className="flex-grow py-8 md:py-12">
        <SetBackButtonBehaviourContext.Provider value={setBackButtonBehaviour}>
          <SetProgressContext.Provider value={setProgress}>
            {children}
          </SetProgressContext.Provider>
        </SetBackButtonBehaviourContext.Provider>
      </main>
    </div>
  );
};
