import { getAppEnv } from '@customer-frontend/types';
import { ensureEnv, Environment } from '@customer-frontend/environment';

const appEnv = getAppEnv(ensureEnv(`REACT_APP_APPLICATION_ENVIRONMENT`));

export const environment: Environment = {
  apiUrl: ensureEnv(`REACT_APP_API_URL`),
  landingPageUrl: ensureEnv(`REACT_APP_LANDING_PAGE_URL`),
  stripe: ensureEnv('REACT_APP_STRIPE_PUBLIC_KEY'),
  appEnv,
  isProd: appEnv === 'production',
  datadogToken: ensureEnv('REACT_APP_DATADOG_TOKEN'),
  datadogRumApplicationId: ensureEnv('REACT_APP_DATADOG_RUM_APPLICATION_ID'),
  datadogRumToken: ensureEnv('REACT_APP_DATADOG_RUM_TOKEN'),
  segmentKey: ensureEnv(`REACT_APP_SEGMENT_PUBLIC_KEY`),
  launchDarklyClientID: ensureEnv('REACT_APP_LAUNCH_DARKLY_CLIENT_ID'),
  mapsApiKey: ensureEnv('REACT_APP_GOOGLE_PLACES_API_KEY'), // the places key has maps api permissions
  googlePlacesApiKey: ensureEnv(`REACT_APP_GOOGLE_PLACES_API_KEY`),
  streamAppKey: ensureEnv('REACT_APP_STREAM_APP_KEY'),
  acuityVars: {
    baseUrl: ensureEnv('REACT_APP_ACUITY_EMBED_URL'),
    consultationIdFieldId: ensureEnv(
      'REACT_APP_ACUITY_CONSULTATION_ID_FIELD_ID',
    ),
    ownerId: ensureEnv('REACT_APP_ACUITY_EMBED_OWNER_ID'),
    userIdFieldId: ensureEnv('REACT_APP_ACUITY_USER_ID_FIELD_ID'),
    apptTypeIds: {
      FERTILITY_HORMONE: ensureEnv(
        'REACT_APP_ACUITY_FERTILITY_HORMONE_APPT_TYPE_ID',
      ),
      FERTILITY: ensureEnv('REACT_APP_ACUITY_FERTILITY_APPT_TYPE_ID'),
      PREGNANCY: ensureEnv('REACT_APP_ACUITY_PREGNANCY_APPT_TYPE_ID'),
      POSTPARTUM: ensureEnv('REACT_APP_ACUITY_POSTPARTUM_APPT_TYPE_ID'),
      PRECONCEPTION_FERTILITY: ensureEnv(
        'REACT_APP_ACUITY_PRECONCEPTION_FERTILITY_APPT_TYPE_ID',
      ),
      PRECONCEPTION_NUTRITION: ensureEnv(
        'REACT_APP_ACUITY_PRECONCEPTION_NUTRITION_APPT_TYPE_ID',
      ),
      PRECONCEPTION_MENTAL_HEALTH: ensureEnv(
        'REACT_APP_ACUITY_PRECONCEPTION_MENTAL_HEALTH_APPT_TYPE_ID',
      ),
      PRECONCEPTION_PHYSIO: ensureEnv(
        'REACT_APP_ACUITY_PRECONCEPTION_PHYSIO_APPT_TYPE_ID',
      ),
      PREGNANCY_NUTRITION: ensureEnv(
        'REACT_APP_ACUITY_PREGNANCY_NUTRITION_APPT_TYPE_ID',
      ),
      PREGNANCY_MENTAL_HEALTH: ensureEnv(
        'REACT_APP_ACUITY_PREGNANCY_MENTAL_HEALTH_APPT_TYPE_ID',
      ),
      PREGNANCY_PHYSIO: ensureEnv(
        'REACT_APP_ACUITY_PREGNANCY_PHYSIO_APPT_TYPE_ID',
      ),
      POSTPARTUM_NUTRITION: ensureEnv(
        'REACT_APP_ACUITY_POSTPARTUM_NUTRITION_APPT_TYPE_ID',
      ),
      POSTPARTUM_MENTAL_HEALTH: ensureEnv(
        'REACT_APP_ACUITY_POSTPARTUM_MENTAL_HEALTH_APPT_TYPE_ID',
      ),
      POSTPARTUM_PHYSIO: ensureEnv(
        'REACT_APP_ACUITY_POSTPARTUM_PHYSIO_APPT_TYPE_ID',
      ),
      BREASTFEEDING: ensureEnv('REACT_APP_ACUITY_BREASTFEEDING_APPT_TYPE_ID'),
      INFANT_SLEEP: ensureEnv('REACT_APP_ACUITY_INFANT_SLEEP_APPT_TYPE_ID'),
    },
  },
};
