import React, { useState } from 'react';

import { FooterView } from './view';
import { useAuth } from '@customer-frontend/auth';

export const Footer = (): React.ReactElement => {
  const { loggedInUser } = useAuth();
  const [isSignedUp, setIsSignedUp] = useState<boolean>(false);

  const handleKlaviyoSubmit = (): void => {
    setIsSignedUp(true);
  };

  return (
    <FooterView
      user={loggedInUser}
      isSignedUp={isSignedUp}
      onKlaviyoSubmit={handleKlaviyoSubmit}
    />
  );
};
