import React from 'react';
import {
  Redirect,
  Route,
  Router,
  Switch,
  useRouteMatch,
} from 'react-router-dom';
import {
  PrivateRoute,
  PublicRoute,
  RouteTracker,
  AppRedirect,
} from '@customer-frontend/route';
import { CompatRouter } from 'react-router-dom-v5-compat';

import { SimpleLayout } from 'components/navigation/simple-layout';
import {
  Layout as DefaultLayout,
  PurpleHeaderLayout,
} from 'components/navigation/layout';
import { routes } from 'utils/routes';
import { history } from 'services/history';
import {
  FeatureFlagMultivariate,
  useFeatureFlagBoolean,
} from '@customer-frontend/feature-flags';
import FertilityTestConfirm from 'pages/consultation/confirm';
import { OTCCartProvider } from '@customer-frontend/order';
import { ChatLayout } from 'pages/consultation/chat/chat-layout';
import { ImpersonatingUserBanner } from '@customer-frontend/auth';

const ConsultationPlan = React.lazy(() => import('./pages/consultation/plan'));
const PlanLegacy = React.lazy(() => import('./pages/consultation/plan-legacy'));
const DoctorsNote = React.lazy(
  () => import('./pages/consultation/doctors-note'),
);
const PharmacySafetyInformation = React.lazy(
  () => import('./pages/consultation/pharmacy-safety-information'),
);
const PharmacyInfoLegacy = React.lazy(
  () => import('./pages/consultation/pharmacy-info-legacy'),
);
const Add = React.lazy(() => import('./pages/consultation/add'));
const Shipping = React.lazy(() => import('./pages/consultation/shipping'));

const Login = React.lazy(() => import('./pages/auth/login'));
const Signup = React.lazy(() => import('./pages/auth/signup'));
const ForgotPassword = React.lazy(() => import('./pages/auth/forgot-password'));
const ResetPassword = React.lazy(() => import('./pages/auth/reset-password'));

const Start = React.lazy(() => import('./pages/start/start'));

const Quiz = React.lazy(() => import('./pages/quiz/quiz'));
const QuizComplete = React.lazy(() => import('./pages/quiz/quiz-complete'));
const QuizSummary = React.lazy(() => import('./pages/quiz/quiz-summary'));
const FollowUp = React.lazy(() => import('./pages/consultation/follow-up'));

const RedirectToQuiz = React.lazy(
  () => import('./pages/quiz/redirect-to-quiz'),
);

const RedirectToQuizSummary = React.lazy(
  () => import('./pages/quiz/redirect-to-quiz-summary'),
);

const Complete = React.lazy(() => import('./pages/consultation/complete'));
const Results = React.lazy(() => import('./pages/consultation/results'));

const Dashboard = React.lazy(() => import('./pages/dashboard'));

const CollectMedicare = React.lazy(
  () => import('./pages/consultation/collect-medicare'),
);

const CollectAddress = React.lazy(
  () => import('./pages/consultation/collect-address'),
);

const ConsultationPayment = React.lazy(
  () => import('./pages/consultation/payment/consultation-payment'),
);
const VideoConsultationNotes = React.lazy(
  () => import('./pages/consultation/notes'),
);
const FertilityTestPayment = React.lazy(
  () => import('./pages/consultation/payment/fertility-test-payment'),
);

const ConsultationReview = React.lazy(
  () => import('./pages/consultation/review'),
);
const ConsultationRedirect = React.lazy(
  () => import('./pages/consultation/redirect'),
);

const ConsultationChat = React.lazy(() => import('./pages/consultation/chat'));

const PractitionerPhoneCall = React.lazy(
  () => import('./pages/consultation/phone-call/practitioner-phone-call'),
);
const SchedulePhoneCall = React.lazy(
  () => import('./pages/consultation/phone-call/schedule-phone-call'),
);

const ProfileMedicare = React.lazy(() => import('./pages/medicare'));
const Subscriptions = React.lazy(() => import('./pages/subscriptions'));
const Profile = React.lazy(() => import('./pages/profile'));
const TreatmentPage = React.lazy(() => import('./pages/treatment'));

const PregnancyChecklist = React.lazy(
  () => import('./pages/checklist/pregnancy-checklist'),
);
const ConceptionChecklist = React.lazy(
  () => import('./pages/checklist/conception-checklist'),
);

const ScheduleFertilityTestVideoConsult = React.lazy(
  () => import('./pages/consultation/schedule/fertility-test-schedule'),
);
const ScheduleVideoConsult = React.lazy(
  () => import('./pages/consultation/schedule/consultation-schedule'),
);

const MasterclassHome = React.lazy(() => import('./pages/masterclass/home'));
const MasterclassPayment = React.lazy(
  () => import('./pages/masterclass/payment'),
);
const MasterclassPaymentSuccess = React.lazy(
  () => import('./pages/masterclass/payment/success'),
);
const MasterclassClass = React.lazy(() => import('./pages/masterclass/class'));

const CallConfirm = React.lazy(
  () => import('./pages/consultation/phone-call/practitioner-call-confirmed'),
);

const Error = React.lazy(() => import('./pages/error'));

const FertilityTestRoutes = (): React.ReactElement => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <PrivateRoute layout={DefaultLayout} path={`${path}/dashboard`}>
        <Dashboard />
      </PrivateRoute>
      <PrivateRoute layout={SimpleLayout} path={`${path}/payment`}>
        <FertilityTestPayment />
      </PrivateRoute>
      <PrivateRoute layout={SimpleLayout} path={`${path}/confirm`}>
        <FertilityTestConfirm />
      </PrivateRoute>
      <PrivateRoute layout={SimpleLayout} path={`${path}/schedule`}>
        <ScheduleFertilityTestVideoConsult />
      </PrivateRoute>
    </Switch>
  );
};

function QuizRoutes(): React.ReactElement {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/:quizApplicationId/summary`}>
        <QuizSummary />
      </Route>
      <Route path={`${path}/:quizApplicationId/complete`}>
        <QuizComplete />
      </Route>
      <Route
        path={[
          `${path}/:quizApplicationId/question/:questionId`,
          `${path}/:quizApplicationId`,
        ]}
      >
        <Quiz />
      </Route>
    </Switch>
  );
}

function MasterclassRoutes(): React.ReactElement {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/home`}>
        <MasterclassHome />
      </Route>
      <Route path={`${path}/payment`}>
        <MasterclassPayment />
      </Route>
      <Route path={`${path}/success`}>
        <MasterclassPaymentSuccess />
      </Route>
      <Route path={`${path}/class/:classId`}>
        <MasterclassClass />
      </Route>
    </Switch>
  );
}

const ConsultationRoutes = (): React.ReactElement => {
  const { path } = useRouteMatch();
  const isSplitPlanFlow = useFeatureFlagBoolean(
    'FF_CONSULTATION_ORDER_PAYMENT_SPLIT_FLOW',
  );

  return (
    <OTCCartProvider>
      <Switch>
        <PrivateRoute path={`${path}/fertilityTest`}>
          <FertilityTestRoutes />
        </PrivateRoute>
        <PrivateRoute exact layout={SimpleLayout} path={`${path}/quiz-summary`}>
          <RedirectToQuizSummary />
        </PrivateRoute>
        <PrivateRoute exact layout={SimpleLayout} path={`${path}/complete`}>
          <Complete />
        </PrivateRoute>
        <PrivateRoute exact layout={SimpleLayout} path={`${path}/results`}>
          <Results />
        </PrivateRoute>
        <PrivateRoute exact layout={SimpleLayout} path={`${path}/follow-up`}>
          <FollowUp />
        </PrivateRoute>
        <PrivateRoute layout={SimpleLayout} path={`${path}/schedule`}>
          <ScheduleVideoConsult />
        </PrivateRoute>
        <PrivateRoute layout={SimpleLayout} path={`${path}/plan`}>
          {isSplitPlanFlow ? <ConsultationPlan /> : <PlanLegacy />}
        </PrivateRoute>
        <PrivateRoute layout={SimpleLayout} path={`${path}/doctors-note`}>
          <DoctorsNote />
        </PrivateRoute>
        <PrivateRoute layout={ChatLayout} path={`${path}/chat`}>
          <ConsultationChat />
        </PrivateRoute>
        <PrivateRoute layout={SimpleLayout} path={`${path}/phone-call`}>
          <PractitionerPhoneCall />
        </PrivateRoute>
        <PrivateRoute layout={SimpleLayout} path={`${path}/schedule-call`}>
          <SchedulePhoneCall />
        </PrivateRoute>
        <PrivateRoute layout={SimpleLayout} path={`${path}/pharmacyInfo`}>
          {isSplitPlanFlow ? (
            <PharmacySafetyInformation />
          ) : (
            <PharmacyInfoLegacy />
          )}
        </PrivateRoute>
        <PrivateRoute layout={SimpleLayout} path={`${path}/add`}>
          <Add />
        </PrivateRoute>
        <PrivateRoute layout={SimpleLayout} path={`${path}/shipping`}>
          <Shipping />
        </PrivateRoute>
        <PrivateRoute exact layout={PurpleHeaderLayout} path={`${path}/review`}>
          <ConsultationReview />
        </PrivateRoute>
        <PrivateRoute
          layout={PurpleHeaderLayout}
          path={`${path}/conceptionChecklist`}
        >
          <ConceptionChecklist />
        </PrivateRoute>
        <PrivateRoute
          layout={PurpleHeaderLayout}
          path={`${path}/pregnancyChecklist`}
        >
          <PregnancyChecklist />
        </PrivateRoute>
        <PrivateRoute path={`${path}/quiz`}>
          <RedirectToQuiz />
        </PrivateRoute>
        <PrivateRoute
          layout={SimpleLayout}
          path={`${path}/paymentConsultation`}
        >
          <ConsultationPayment />
        </PrivateRoute>
        <PrivateRoute path={`${path}/notes`}>
          <VideoConsultationNotes />
        </PrivateRoute>
        <PrivateRoute exact path={path}>
          <ConsultationRedirect />
        </PrivateRoute>
        <FeatureFlagMultivariate
          flagKey={'ENABLE_MEDICARE_COLLECTION'}
          truthyFlagValues={['variation']}
        >
          <PrivateRoute
            exact
            layout={SimpleLayout}
            path={`${path}/collect-medicare`}
          >
            <CollectMedicare />
          </PrivateRoute>
          <PrivateRoute
            exact
            layout={SimpleLayout}
            path={`${path}/collect-address`}
          >
            <CollectAddress />
          </PrivateRoute>
        </FeatureFlagMultivariate>
      </Switch>
    </OTCCartProvider>
  );
};

export const Routes = (): React.ReactElement => (
  <Router history={history}>
    <CompatRouter>
      <ImpersonatingUserBanner />
      <RouteTracker />
      <Switch>
        <Route path="/app">
          <AppRedirect />
        </Route>
        <PublicRoute layout={SimpleLayout} path={routes.login}>
          <Login />
        </PublicRoute>
        <PublicRoute layout={SimpleLayout} path={routes.signup}>
          <Signup />
        </PublicRoute>
        <PublicRoute layout={DefaultLayout} path={routes.masterclass.root}>
          <MasterclassRoutes />
        </PublicRoute>
        <PublicRoute layout={SimpleLayout} path={routes.forgotPassword}>
          <ForgotPassword />
        </PublicRoute>
        <PublicRoute layout={SimpleLayout} path={routes.reset}>
          <ResetPassword />
        </PublicRoute>
        <PublicRoute layout={SimpleLayout} path={routes.start.root}>
          <Start />
        </PublicRoute>
        <PrivateRoute
          path="/profile/treatments/:treatmentId"
          layout={DefaultLayout}
        >
          <TreatmentPage />
        </PrivateRoute>
        <PrivateRoute
          exact
          layout={DefaultLayout}
          path={routes.profileMedicare}
        >
          <ProfileMedicare />
        </PrivateRoute>
        <PrivateRoute
          exact
          layout={DefaultLayout}
          path={routes.profileSubscriptions}
        >
          <Subscriptions />
        </PrivateRoute>
        <PrivateRoute layout={PurpleHeaderLayout} path={routes.profile}>
          <Profile />
        </PrivateRoute>
        <PrivateRoute layout={SimpleLayout} path="/quiz">
          <QuizRoutes />
        </PrivateRoute>
        <Route path="/consultation/:consultationId">
          <ConsultationRoutes />
        </Route>
        <PrivateRoute path="/call/:pracBookingId/confirmation">
          <CallConfirm />
        </PrivateRoute>
        <Route path="/" exact>
          <Redirect
            to={{
              pathname: routes.profile,
            }}
          />
        </Route>
        <PublicRoute layout={DefaultLayout}>
          <Error />
        </PublicRoute>
      </Switch>
    </CompatRouter>
  </Router>
);
