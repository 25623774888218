import type { Progress } from '@customer-frontend/navbar';

export const shouldShowProgressBar = (progress: Progress): boolean => {
  return (
    !!progress && progress.currentStepIndex > -1 && progress.lastStepIndex > -1
  );
};

export const calcProgressValue = (progress: Progress): number => {
  if (progress.currentStepIndex < 0 || progress.lastStepIndex < 0) {
    return 0;
  }

  if (progress.currentStepIndex > progress.lastStepIndex) {
    return 0;
  }

  return (100 * (progress.currentStepIndex + 1)) / (progress.lastStepIndex + 1);
};
