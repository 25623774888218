import React from 'react';
import clsx from 'clsx';
import { UserInfo } from '@customer-frontend/auth';
import { ProfileIcon } from '../profile-icon';
import { ReactComponent as KinLogo } from '../../../assets/kin-logo-v2.svg';
import { ReactComponent as HamburgerIcon } from '../assets/hamburger.svg';
import { MobileMenu } from './mobile-menu';
import { environment } from '../../../utils/environment';
import { menuRoutes, menuBorderColors } from '../constants';
import type { HeaderColor } from './types';
import { Link } from 'react-router-dom';
import { routes } from '../../../utils/routes';

const menuItem =
  'border-none rounded-sm active:bg-black active:bg-opacity-10 hover:bg-none focus:outline-none';
const navbarItem = 'py-3 px-3 text-base font-sans font-bold tracking-wide';

interface HeaderViewProps {
  user?: UserInfo;
  mainMenuItems: string[];
  mobileMenuOpen: boolean;
  hoveredMenuItem?: string;
  color?: HeaderColor;
  onMobileMenuToggle: () => void;
  onMenuItemHover: (menuItem?: string) => void;
}

export const HeaderView = ({
  user,
  mainMenuItems,
  mobileMenuOpen,
  hoveredMenuItem,
  color = 'cream',
  onMobileMenuToggle,
  onMenuItemHover,
}: HeaderViewProps): React.ReactElement => {
  return (
    <header
      className={clsx(
        'fixed left-0 right-0 top-0 z-10',
        color === 'purple'
          ? 'bg-primary-purple-500 text-white'
          : 'bg-beige-500 text-primary-purple-600',
      )}
      onMouseLeave={() => onMenuItemHover()}
    >
      <MobileMenu isOpen={mobileMenuOpen} onClose={onMobileMenuToggle} />
      <nav
        className={clsx('px-2 py-4 lg:py-0 min-h-14 lg:px-0 relative z-10', {
          'shadow-nav':
            color === 'cream' || (color === 'purple' && hoveredMenuItem),
        })}
        role="navigation"
        aria-label="main navigation"
      >
        <div className="px-2 lg:px-12 flex items-center justify-between">
          <div className="w-full flex items-center justify-between">
            {/* <!-- MOBILE MENU ONLY --> */}
            <div className="flex w-6 h-6 overflow-hidden lg:hidden">
              <button
                className={`${menuItem} cursor-pointer`}
                aria-label="menu"
                aria-expanded="false"
                onClick={onMobileMenuToggle}
              >
                <HamburgerIcon />
              </button>
            </div>
            <Link
              className="block w-12"
              aria-label="Navigate home"
              to={routes.profile}
              onMouseEnter={() => onMenuItemHover()}
            >
              <KinLogo aria-label="Kin logo" />
            </Link>
            <div className="lg:hidden">
              {user?.firstName && (
                <ProfileIcon variant={color === 'cream' ? 'light' : 'dark'} />
              )}
            </div>
            <div className="hidden lg:flex justify-between lg:flex-grow">
              <ul className="flex tw-flex-1 max-w-2xl w-full justify-between items-center ml-8">
                {mainMenuItems.map((menuItem) => {
                  const borderClassName = menuBorderColors[menuItem];

                  return (
                    <li
                      key={menuItem}
                      className={clsx(
                        'pb-3 pt-2 flex border-b-3 relative -bottom-0.75 -mt-0.5 border-transparent',
                        {
                          [borderClassName]: hoveredMenuItem === menuItem,
                        },
                      )}
                      onMouseEnter={() => onMenuItemHover(menuItem)}
                    >
                      <a
                        className={navbarItem}
                        href={`${environment.landingPageUrl}/${
                          menuItem === 'Contraception'
                            ? 'the-pill'
                            : menuItem.toLowerCase()
                        }`}
                      >
                        {menuItem}
                      </a>
                    </li>
                  );
                })}
              </ul>
              <div
                className="justify-between items-center tw-flex-1 hidden lg:flex"
                onMouseEnter={() => onMenuItemHover()}
              >
                <a
                  className={clsx(navbarItem, 'px-6')}
                  href={`${environment.landingPageUrl}/guides`}
                >
                  Learn
                </a>
                <div className="profile-button-container">
                  {user?.firstName && (
                    <ProfileIcon
                      variant={color === 'cream' ? 'light' : 'dark'}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      {hoveredMenuItem && (
        <div
          className={clsx(
            'hidden lg:block absolute left-0 right-0 py-6 pl-36',
            color === 'purple'
              ? 'bg-primary-purple-600 shadow-nav-menu'
              : 'bg-secondary-cream-200 shadow-sm',
          )}
        >
          <ul>
            {menuRoutes[hoveredMenuItem].map((subMenuItem) => {
              return (
                <li key={subMenuItem.name} className="py-2 flex items-center">
                  <a href={`${environment.landingPageUrl}${subMenuItem.to}`}>
                    {subMenuItem.name}
                  </a>
                  {subMenuItem.isNew && (
                    <span className="px-1 ml-3 rounded-sm text-white text-xs bg-warm-500">
                      New
                    </span>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </header>
  );
};
