import { showFile } from '@customer-frontend/utils';

export const downloadPdfFile = async ({
  url,
  fileName,
  onError,
}: {
  url: string;
  fileName: string;
  onError: (error: Error) => void;
}): Promise<void> => {
  try {
    // eslint-disable-next-line no-undef
    const response = await fetch(url);
    const blob = await response.blob();
    showFile(blob, `${fileName}.pdf`);
  } catch (error) {
    onError(error);
  }
};
