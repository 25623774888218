import { useParams } from 'react-router-dom';

type QuizUrlParams = {
  quizApplicationId: string;
};

export const useQuizApplicationIdFromUrl = (): string => {
  const { quizApplicationId } = useParams<QuizUrlParams>();
  return quizApplicationId;
};
