import React from 'react';
import clsx from 'clsx';

import { ReactComponent as ProfileBold } from './profile-bold.svg';
import { Link } from 'react-router-dom';
import { routes } from 'utils/routes';

interface ProfileIconProps {
  variant?: string;
  onClick?: () => void;
}

export const ProfileIcon = ({
  variant = 'light',
  onClick,
}: ProfileIconProps): React.ReactElement => {
  const isLight = variant === 'light';
  const colorConfig = isLight ? 'border-primary-800' : 'border-white';

  return (
    <Link
      rel="noreferrer"
      className={clsx(
        'flex items-center font-semibold py-2 px-4 md:px-6 border rounded-md space-x-2 justify-center',
        colorConfig,
      )}
      to={routes.profile}
      onClick={onClick}
    >
      <div className="flex items-center w-5 h-5 sm:w-6 sm:h-6 justify-center">
        <ProfileBold className={isLight ? 'fill-white' : 'fill-primary-500'} />
      </div>
      <span className="hidden sm:inline-block">Profile</span>
    </Link>
  );
};
