import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { environment } from 'utils/environment';
import { logger } from 'utils/logging';

const stripePromise = loadStripe(environment.stripe).catch((error) => {
  logger.error(error);
  return null;
});

export const StripeProvider = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement => {
  return <Elements stripe={stripePromise}>{children}</Elements>;
};
