import { Button, Typography } from '@eucalyptusvc/design-system';
import { EmptyCartProps } from './types';
import { FormattedMessage } from 'react-intl';

export const OTCEmptyCart = ({
  subHeading,
  onCtaClick,
  ctaText,
  image,
  onClose,
}: EmptyCartProps): JSX.Element => {
  const handleCtaClicked = (): void => {
    onCtaClick?.();
    onClose();
  };
  return (
    <div className="text-center px-6 py-8 space-y-8">
      <Typography element="h3" size="md" isBold>
        <FormattedMessage defaultMessage="Your cart is empty" />
      </Typography>
      {subHeading && (
        <Typography size="medium-paragraph">{subHeading}</Typography>
      )}
      <div className="flex justify-center"> {image}</div>
      <Button palette="alternate" isFullWidth onClick={handleCtaClicked}>
        {ctaText ? (
          ctaText
        ) : (
          <FormattedMessage
            defaultMessage="Close"
            description="Button text to close the OTC cart"
          />
        )}
      </Button>
    </div>
  );
};
