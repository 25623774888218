import clsx from 'clsx';

type SimplePageLayoutProps = {
  className?: string;
  children: React.ReactNode;
};

const SimplePageLayout = ({
  className,
  children,
}: SimplePageLayoutProps): JSX.Element => {
  return (
    <div className={clsx('max-w-screen-sm mx-auto px-4 md:px-8', className)}>
      {children}
    </div>
  );
};

export default SimplePageLayout;
