import { Divider, Typography } from '@eucalyptusvc/design-system';
import { ReactComponent as CalenderIcon } from '../../../../assets/icons/calendar-bold.svg';
import { ReactComponent as MapPin } from '../../../../assets/icons/map-pin.svg';
import { ReactComponent as IdCard } from '../../../../assets/icons/id-card-bold.svg';
import { ReactComponent as Phone } from '../../../../assets/icons/phone-bold.svg';
import { FaExclamationTriangle } from 'react-icons/fa';
import { PaymentInfoCard } from 'pages/consultation/payment/common/payment-info-card';

export const FertilityTestInfoCard = (): JSX.Element => {
  return (
    <PaymentInfoCard
      title="Everything you need to know"
      titleSize="md"
      icon={
        <div className="mt-1">
          <FaExclamationTriangle size="16px" />
        </div>
      }
    >
      <div className="space-y-4">
        <Divider palette="alternate" />
        <div className="space-y-2">
          <div className="flex items-center space-x-2">
            <CalenderIcon className="w-4 " />
            <Typography size="medium-paragraph" isBold>
              When should I test?
            </Typography>
          </div>
          <Typography size="medium-paragraph">
            If you&apos;re testing for any of the ovulation hormones (E2, FSH or
            LH), we highly recommend you wait until Day 2-5 of your period cycle
            to do the test. This is because these hormones fluctuate throughout
            your monthly cycle.
          </Typography>
          <Typography size="medium-paragraph">
            To find day 2-5, count Day 1 as the first day of your full flow that
            starts before 5pm. If you haven&apos;t had your period for the last
            3 months, please take your test at any time.
          </Typography>
          <Typography size="medium-paragraph">
            If you&apos;re not testing for any of the ovulation hormones (E2,
            FSH or LH), you can also take your test at any time.
          </Typography>
        </div>

        <div className="space-y-2">
          <div className="flex items-center space-x-2">
            <MapPin className="w-4 " />
            <Typography size="medium-paragraph" isBold>
              Can I go to any collection centre?
            </Typography>
          </div>
          <Typography size="medium-paragraph">
            It&apos;s important that you only present your Kin pathology request
            form at one of our
            <a
              href="https://www.healius.com.au/patient-services/pathology/"
              target="_blank"
              rel="noreferrer"
              className="text-secondary-electric-purple-200 font-semibold"
            >
              &nbsp;designated collection centres&nbsp;
            </a>
            listed below:
          </Typography>
          <Typography size="medium-paragraph">
            <ul className="space-y-2 ml-10">
              <li className="list-disc">
                QML Pathology - QLD and Northern NSW
              </li>
              <li className="list-disc">Laverty Pathology - NSW and ACT</li>
              <li className="list-disc">Dorevitch Pathology - VIC</li>
              <li className="list-disc">TML Pathology - TAS</li>
              <li className="list-disc">Abbott Pathology - SA</li>
              <li className="list-disc">
                Western Diagnostics Pathology - WA and NT
              </li>
            </ul>
          </Typography>
          <Typography size="medium-paragraph">
            While a non-designated collection centre may accept your form, they
            will bill you again for the test.
          </Typography>
        </div>

        <div className="space-y-2">
          <div className="flex items-center space-x-2">
            <Phone className="w-6 md:w-4" />
            <Typography size="medium-paragraph" isBold>
              Do I need to make an appointment with the collection centre?
            </Typography>
          </div>
          <Typography size="medium-paragraph">
            Generally speaking, no appointment is necessary. But, we recommend
            calling ahead to the collection centre to double check their opening
            hours.
          </Typography>
        </div>

        <div className="space-y-2">
          <div className="flex space-x-2 items-center">
            <IdCard className="w-6 md:w-4" />
            <Typography size="medium-paragraph" isBold>
              Do I need to make an appointment with the collection centre?
            </Typography>
          </div>
          <Typography size="medium-paragraph">
            You&apos;ll need to bring a valid form of ID (e.g. a drivers
            license) and a <span className="font-semibold">printed copy</span>
            &nbsp; of your pathology form. The pathology labs unfortunately do
            not accept digital copies of a referral form.
          </Typography>
          <Typography size="medium-paragraph">
            If you have any questions or need anything else, we&apos;re here for
            you. Even if it&apos;s just to calm any nerves! Nothing is too much
            or too little for us. Just email us at&nbsp;
            <a
              href="mailto:hello@kinfertility.com.au"
              target="_blank"
              rel="noreferrer"
              className="text-secondary-electric-purple-200 font-semibold"
            >
              hello@kinfertility.com.au.
            </a>
          </Typography>
        </div>
      </div>
    </PaymentInfoCard>
  );
};
