import { Card, Typography } from '@eucalyptusvc/design-system';
import { PaymentInfoCardProps } from './types';

export const PaymentInfoCard = ({
  icon,
  title,
  titleSize = 'xl',
  children,
}: PaymentInfoCardProps): JSX.Element => {
  return (
    <Card>
      <div className="flex items-center gap-2">
        {icon && (
          <div className="inline-flex flex-shrink-0 self-baseline">{icon}</div>
        )}
        <Typography size={titleSize} isBold>
          {title}
        </Typography>
      </div>
      <div className="mt-4">{children}</div>
    </Card>
  );
};
