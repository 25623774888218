import { useQuery, gql, QueryResult } from '@apollo/client';
import { Membership } from '@customer-frontend/graphql-types';

export const useMembership = (): QueryResult<{
  profile: {
    memberships: Membership[];
  };
}> => {
  return useQuery<{
    profile: {
      memberships: Membership[];
    };
  }>(gql`
    query UseMembership {
      profile {
        id
        memberships {
          id
          type
          createdAt
          membershipTerms(orderBy: { createdAt: desc }, take: 1) {
            id
            interval
            intervalCount
            subscriptionStatus
            status
            startDate
            endDate
            isValid
          }
        }
      }
    }
  `);
};
