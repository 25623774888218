import { notificationService } from '@customer-frontend/notifications';
import clsx from 'clsx';
import React, { useState } from 'react';
import { validEmail } from 'utils/validation';

const KLAVIYO_FUNCTION_ENDPOINT = '/.netlify/functions/klaviyo';

interface KlaviyoSignupFormProps {
  buttonText: string;
  placeholder: string;
  trackingName: string;
  klaviyoListId: string;
  confirmOptIn: boolean;
  onSuccess: () => void;
  inputClasses?: string[];
  buttonClasses?: string[];
  trackingData: Record<string, unknown>;
}

export const KlaviyoSignupForm = ({
  onSuccess,
  buttonText,
  placeholder,
  // trackingData,
  confirmOptIn,
  // trackingName,
  klaviyoListId,
}: KlaviyoSignupFormProps): React.ReactElement => {
  const [email, setEmail] = useState('');

  // const fireSuccessEvent = (): void => {
  //   if (window && window.analytics) {
  //     window.analytics.track(trackingName, {
  //       email,
  //       ...(trackingData ?? {}),
  //     });
  //   }
  // };

  // const fireErrorEvent = (error: Error): void => {
  //   if (window && window.analytics) {
  //     window.analytics.track('Failed to signup', {
  //       error,
  //       email,
  //     });
  //   }
  // };

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>,
  ): Promise<Promise<void> | void> => {
    e.preventDefault();

    if (!validEmail(email)) {
      notificationService.show({
        type: 'error',
        message: 'Invalid email, please try again.', // Note: There is also browser level validation, this is to catch edge cases.
      });
      return;
    }
    try {
      await fetch(KLAVIYO_FUNCTION_ENDPOINT, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email: email,
          listId: klaviyoListId,
          confirm: confirmOptIn,
        }),
      });

      // fireSuccessEvent();
      onSuccess();
    } catch (error) {
      // fireErrorEvent(error);
      notificationService.show({
        type: 'error',
        message: 'Failed to sign you up. Please try again',
      });
    }
  };

  return (
    <form className="flex items-start" onSubmit={handleSubmit}>
      <div className="control">
        <input
          name="email"
          className={clsx(
            'h-11 outline-none bg-primary-50 px-2',
            'w-full m-w-full rounded-l-md',
            'placeholder-primary-800 text-primary-800 font-semibold',
          )}
          required
          value={email}
          onChange={(e): void => setEmail(e.target.value)}
          type="email"
          placeholder={placeholder}
        />
      </div>
      <div className="control">
        <button className="bg-primary-500 px-3 h-11 rounded-r-md" type="submit">
          {buttonText}
        </button>
      </div>
    </form>
  );
};
