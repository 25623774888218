import { useLocation, Redirect } from 'react-router-dom';

export const AppRedirect = (): React.ReactElement => {
  const location = useLocation();
  return (
    <Redirect
      to={{
        pathname: location.pathname.replace('/app', ''),
        search: location.search,
      }}
    />
  );
};
