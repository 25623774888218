type RouteMap = {
  name: string;
  to: string;
  isNew?: boolean;
};

export const menuBorderColors: Record<string, string> = {
  Contraception: 'border-primary-100',
  Fertility: 'border-warm-300',
  Conceiving: 'border-warm-200',
  Pregnancy: 'border-warm-100',
  Postpartum: 'border-warm-500',
  Supplements: 'border-warm-400',
};

export const menuRoutes: Record<string, RouteMap[]> = {
  Contraception: [
    {
      name: 'The Pill Subscription',
      to: '/the-pill',
    },
  ],
  Fertility: [
    {
      name: 'Fertility Test',
      to: '/fertility-test',
    },
  ],
  Conceiving: [
    {
      name: 'Fertility Test',
      to: '/fertility-test',
    },
    {
      name: 'The Prenatal',
      to: '/the-prenatal',
    },
    {
      name: 'The Male Prenatal',
      to: '/the-male-prenatal',
    },
    {
      name: 'The Fertility Lube',
      to: '/fertility-lube',
    },
    {
      name: 'The Pregnancy Checklist',
      to: '/pregnancy-checklist',
    },
  ],
  Pregnancy: [
    {
      name: 'The Prenatal',
      to: '/the-prenatal',
    },
  ],
  Postpartum: [
    {
      name: 'Postpartum Recovery Kit',
      to: '/postpartum-recovery-kit',
    },
    {
      name: 'Breastfeeding Essentials',
      to: '/breastfeeding-essentials',
    },
  ],
  Supplements: [
    {
      name: 'Supplements',
      to: '/supplements',
    },
  ],
};

export const minorRoutes: RouteMap[] = [
  {
    name: 'Guides',
    to: '/guides',
  },
  {
    name: 'Tidings',
    to: '/tidings',
  },
  {
    name: 'About',
    to: '/about',
  },
  {
    name: 'Contact',
    to: '/contact',
  },
  {
    name: 'Help Center',
    to: 'https://help.kinfertility.com.au',
  },
];
