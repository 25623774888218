import React from 'react';
import {
  useApolloClient,
  Reference,
  useQuery,
  gql,
  useMutation,
} from '@apollo/client';
import {
  OtcShoppingCartDocument,
  ShoppingCartFragment,
  OtcShoppingCartQuery,
  OtcShoppingCartQueryVariables,
  UpdateOtcShoppingCartMutation,
  UpdateOtcShoppingCartMutationVariables,
  PurchaseOtcShoppingCartMutation,
  PurchaseOtcShoppingCartMutationVariables,
  RemoveOtcShoppingCartItemMutation,
  RemoveOtcShoppingCartItemMutationVariables,
} from '@customer-frontend/graphql-types';
import { OTCCartContextState } from './types';
import { shoppingCart } from './graphql/shopping-cart-fragment';

const defaultValues = {
  isOTCCartOpen: false,
  setIsOTCCartOpen: () => {
    return false;
  },
  cartItems: [],
  buyNow: () => Promise.resolve(),
  updateItemInCart: () => Promise.resolve(),
  removeItemFromCart: () => Promise.resolve(),
  purchaseCart: () => Promise.resolve(),
  clearCart: () => Promise.resolve(),
  purchaseLoading: false,
  updateLoading: false,
};
export const OTCCartContext =
  React.createContext<OTCCartContextState>(defaultValues);

export const useOTCCart = (): OTCCartContextState =>
  React.useContext(OTCCartContext);

export const OTCCartProvider = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement => {
  const apolloClient = useApolloClient();
  const updateCacheCart = (
    cart: ShoppingCartFragment | null,
  ): Reference | undefined =>
    apolloClient.writeQuery({
      query: OtcShoppingCartDocument,
      data: {
        otcShoppingCart: cart,
      },
    });

  const [updateOtcShoppingCartMutation, { loading: updateLoading }] =
    useMutation<
      UpdateOtcShoppingCartMutation,
      UpdateOtcShoppingCartMutationVariables
    >(
      gql`
        mutation UpdateOtcShoppingCart($item: OTCShoppingCartItemInput!) {
          updateOtcShoppingCart(item: $item) {
            ...ShoppingCart
          }
        }
        ${shoppingCart}
      `,
      {
        onCompleted: ({ updateOtcShoppingCart }) =>
          updateOtcShoppingCart && updateCacheCart(updateOtcShoppingCart),
      },
    );

  const [purchaseOtcShoppingCart, { loading: purchaseLoading }] = useMutation<
    PurchaseOtcShoppingCartMutation,
    PurchaseOtcShoppingCartMutationVariables
  >(
    gql`
      mutation PurchaseOtcShoppingCart(
        $cartId: String!
        $discountCode: String
      ) {
        purchaseOtcShoppingCart(cartId: $cartId, discountCode: $discountCode) {
          ...ShoppingCart
        }
      }
      ${shoppingCart}
    `,
    { onCompleted: () => updateCacheCart(null) },
  );

  const [removeOtcShoppingCartItem] = useMutation<
    RemoveOtcShoppingCartItemMutation,
    RemoveOtcShoppingCartItemMutationVariables
  >(
    gql`
      mutation RemoveOtcShoppingCartItem(
        $cartId: String!
        $variantId: String!
      ) {
        removeOtcShoppingCartItem(cartId: $cartId, variantId: $variantId) {
          ...ShoppingCart
        }
      }
      ${shoppingCart}
    `,
  );

  const { data } = useQuery<
    OtcShoppingCartQuery,
    OtcShoppingCartQueryVariables
  >(
    gql`
      query OtcShoppingCart {
        otcShoppingCart {
          ...ShoppingCart
        }
      }
      ${shoppingCart}
    `,
    {
      fetchPolicy: 'cache-first',
    },
  );

  const cart = data?.otcShoppingCart;
  const cartId = cart?.id;
  const cartItems = cart && cart.items ? cart.items : [];

  const [isOTCCartOpen, setIsOTCCartOpen] = React.useState<boolean>(false);

  const getQuantityIfInCart = (variantId: string): number | undefined => {
    const item = cartItems.find((item) => item.variant.id === variantId);
    if (item) {
      return item.quantity;
    }

    return undefined;
  };

  const buyNow = async (variantId: string): Promise<void> => {
    const quantity: number | undefined = getQuantityIfInCart(variantId);

    if (quantity) {
      await updateItemInCart(variantId, quantity + 1);
    } else {
      await updateItemInCart(variantId, 1);
    }
  };

  const updateItemInCart = async (
    variantId: string,
    quantity = 1,
  ): Promise<void> => {
    await updateOtcShoppingCartMutation({
      variables: { item: { variantId, quantity } },
    });
    setIsOTCCartOpen(true);
  };

  const removeItemFromCart = async (variantId: string): Promise<void> => {
    if (cartId) {
      await removeOtcShoppingCartItem({
        variables: { variantId, cartId },
      });
    }
  };

  const purchaseCart = async (discountCode?: string): Promise<void> => {
    if (cartId) {
      await purchaseOtcShoppingCart({
        variables: { cartId, discountCode },
      });
    }
  };

  const clearCart = async (): Promise<void> => {
    if (cartId && cartItems) {
      await Promise.all(
        cartItems.map(({ variant }) => {
          return removeOtcShoppingCartItem({
            variables: { variantId: variant.id, cartId },
          });
        }),
      );
    }
  };

  const value = {
    cartItems,
    clearCart,
    purchaseCart,
    isOTCCartOpen,
    updateLoading,
    purchaseLoading,
    setIsOTCCartOpen,
    updateItemInCart,
    buyNow,
    removeItemFromCart,
  };

  return (
    <OTCCartContext.Provider value={value}>{children}</OTCCartContext.Provider>
  );
};
