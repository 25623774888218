import React, { useState } from 'react';
import clsx from 'clsx';
import { ReactComponent as KinLogo } from '../../../assets/kin-logo-v2.svg';
import { ReactComponent as CrossIcon } from '../assets/cross.svg';
import { ReactComponent as DownChevron } from '../assets/down-chevron.svg';
import { menuRoutes, minorRoutes } from '../constants';
import { ProfileIcon } from '../profile-icon';
import { environment } from '../../../utils/environment';

const menuHeadings = Object.keys(menuRoutes);

interface MobileMenuProps {
  onClose: () => void;
  isOpen: boolean;
}

interface AccordionState {
  [key: string]: boolean;
}

export const MobileMenu = ({
  onClose,
  isOpen,
}: MobileMenuProps): React.ReactElement | null => {
  const [accordionMap, setAccordionMap] = useState(
    menuHeadings.reduce((acc, val: string) => {
      acc[val] = false;
      return acc;
    }, {} as AccordionState),
  );

  const handleAccordionToggle = (val: string): void => {
    setAccordionMap((vals: AccordionState) => ({
      ...vals,
      [val]: !vals[val],
    }));
  };

  const transformClass = isOpen ? 'translate-x-0' : '-translate-x-full';

  return (
    <div
      className={clsx(
        `fixed overflow-auto top-0 bottom-0 left-0 right-0`,
        `bg-primary-50 text-primary-purple-600 p-6 z-20`,
        `transition ease-in-out duration-300 md:duration-500 transform ${transformClass}`,
      )}
    >
      <div className="flex items-center justify-between">
        <div className="h-8 w-10">
          <a
            role="button"
            aria-label="menu"
            aria-expanded="false"
            onClick={onClose}
            href="/"
          >
            <KinLogo />
          </a>
        </div>
        <button
          className="focus:outline-none"
          aria-label="menu"
          aria-expanded="false"
          onClick={onClose}
        >
          <CrossIcon />
        </button>
      </div>
      <nav>
        <ul className="mt-8 border-t border-primary-100">
          {menuHeadings.map((menuHeading) => {
            const isSectionOpen = accordionMap[menuHeading];

            return (
              <li
                key={menuHeading}
                className="border-b border-primary-100 flex flex-col"
              >
                <button
                  aria-controls={`${menuHeading}Collapse`}
                  className="w-full focus:outline-none flex items-center justify-between py-4 text-primary-800 font-semibold"
                  onClick={() => handleAccordionToggle(menuHeading)}
                >
                  {menuHeading}
                  <div
                    className={clsx({ 'transform rotate-180': isSectionOpen })}
                  >
                    <DownChevron />
                  </div>
                </button>
                {accordionMap[menuHeading] && ( // TODO: handle accordion animation
                  <ul className="pl-8 mb-4 text-primary-purple-600">
                    {menuRoutes[menuHeading].map((menuRoute) => {
                      return (
                        <li key={menuRoute.name}>
                          <a
                            href={`${environment.landingPageUrl}${menuRoute.to}`}
                          >
                            <div className="py-2">
                              {menuRoute.name}&nbsp;&nbsp;
                              {menuRoute.isNew && (
                                <span className="px-1 py-0.5 ml-1 rounded-sm text-white text-xs bg-warm-500">
                                  New
                                </span>
                              )}
                            </div>
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </li>
            );
          })}
        </ul>
        <ul className="mt-4 text-primary-purple-600">
          {minorRoutes.map((minorRoute) => {
            return (
              <li key={minorRoute.to} className="py-1">
                <a
                  className="menu-link"
                  href={`${environment.landingPageUrl}${minorRoute.to}`}
                >
                  {minorRoute.name}
                </a>
              </li>
            );
          })}
          <li className="flex mt-4">
            <ProfileIcon variant="dark" />
          </li>
        </ul>
      </nav>
    </div>
  );
};
