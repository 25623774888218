import { Divider, Typography } from '@eucalyptusvc/design-system';
import { formatCentsToCurrency, useOTCCart } from '@customer-frontend/order';
import { QuantityInput } from '../../../quantity-input';
import { FormattedMessage } from 'react-intl';

export const OTCOrderDetailsList = (): JSX.Element => {
  const { cartItems, updateItemInCart, removeItemFromCart, updateLoading } =
    useOTCCart();
  return (
    <div className="space-y-6">
      {cartItems.map(({ variant, quantity }, index) => {
        const { price, product } = variant;
        return (
          <div key={variant.id}>
            {index > 0 && <Divider variant="separator" palette="alternate" />}
            <div className="flex gap-4">
              <img
                className="object-cover w-16 h-16 rounded-lg"
                src={product?.photo?.url}
              />
              <div className="w-full space-y-3">
                <div className="w-full flex justify-between">
                  <Typography size="medium-paragraph">
                    {product.name}
                  </Typography>
                  <Typography size="medium-paragraph">
                    {formatCentsToCurrency(price * quantity)}
                  </Typography>
                </div>
                <div className="w-full flex justify-between">
                  <QuantityInput
                    disabled={updateLoading}
                    value={quantity}
                    onChange={(value: number) =>
                      updateItemInCart(variant.id, value)
                    }
                  />
                  <span
                    onClick={() => removeItemFromCart(variant.id)}
                    className="cursor-pointer"
                    style={{ color: '#828282' }}
                  >
                    <FormattedMessage
                      defaultMessage="Remove"
                      description="Button to remove an item from the current order"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
